'use client';
import { usePathname } from 'next/navigation';
import SlideUpTransition from '~/core/ui/SlideUpTransition';
import I18nProvider from '~/i18n/I18nProvider';
import Image from 'next/image';
import { useGetComponentConfig } from '@portal/branding/hooks/useGetComponentConfig';
import { SessionProvider } from 'next-auth/react';

function AuthPageShell({
  children,
  language,
}: React.PropsWithChildren<{
  language?: string;
}>) {
  const pathname = usePathname();
  const { data, isLoading } = useGetComponentConfig<{
    src: string;
    height: number;
    width: number;
  }>('images.auth-page-logo');

  if (pathname?.includes('/auth/link')) {
    return children;
  }

  return (
    <div className="mx-auto w-full md:mx-0">
      <SlideUpTransition>
        <div className="flex h-screen w-full items-center justify-center space-y-4 md:space-y-8 lg:bg-gray-50 dark:lg:bg-black-500">
          <div className="flex h-screen w-full flex-col items-center justify-center rounded-xl border-transparent bg-white dark:bg-black-500">
            <div className="flex w-full max-w-sm flex-col items-center space-y-8 px-2 py-1">
              {!isLoading && data && <Image {...data.props} alt="Auth page logo" />}

              <I18nProvider lang={language}>
                <SessionProvider>{children}</SessionProvider>
              </I18nProvider>
            </div>
          </div>
        </div>
      </SlideUpTransition>
    </div>
  );
}

export default AuthPageShell;
